body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fffae3 inset;
  transition: background-color 5000s ease-in-out 0s;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

body::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #FFEB8A;
}

div::-webkit-scrollbar {
  width: 6px;
}

textarea::-webkit-scrollbar {
  width: 3px;
}

div::-webkit-scrollbar-thumb, textarea::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #2F3733;
}